import React from "react";
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaYoutubeSquare,
} from "react-icons/fa";
import { FormattedMessage } from "react-intl";

const Footer = () => {
  return (
    <footer className="bg-white-700 border-t-2">
      <nav className="flex justify-between items-center px-2 py-4 mx-auto text-sm md:p-8">
        <div className="text-xs md:text-sm w-4/5">
          <p>
            <FormattedMessage id="footer_text" />
          </p>
          <p className="items-center">
            <span className="text-lg">&copy;</span>2021. All rights reserved.
          </p>
        </div>
        <div className="flex flex-wrap content-center justify-start">
          <a
            href="https://www.facebook.com/National-Unity-Government-NUG-105408625013111/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookSquare
              color="#3478cf"
              className="hover:opacity-70 text-base md:text-3xl"
            />
          </a>
          <a
            href="https://twitter.com/NUGMyanmar"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitterSquare
              color="#3BA1F2"
              className="hover:opacity-70 ml-1 md:ml-2 text-base md:text-3xl"
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCJuCQN7bM8X0kR4TOwE3Ftw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutubeSquare
              color="red"
              className="hover:opacity-60  ml-1 md:ml-2 text-base md:text-3xl"
            />
          </a>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
