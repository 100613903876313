import PropTypes from "prop-types";
import { Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { FormattedMessage } from "react-intl";
import { Location } from "@reach/router";

const routeUrl = (lang, pathname) => {
  let slug = pathname.replace("/en/", "/");
  return lang === "en" ? "/en" + slug : slug;
};

function Header({ showLangSwitch = true, location }) {
  return (
    <header>
      <div className="bg-tainted-blue">
        <div className="flex flex-wrap flex-row items-center justify-between px-3 py-2 mx-auto md:p-8">
          <Location>
            {({ location }) => {
              if (location.pathname.includes("/en")) {
                return (
                  <Link to="/en">
                    <h1 className="flex items-center text-white no-underline">
                      <StaticImage
                        src="../images/emblem.png"
                        alt="National Emblem of Myanmar"
                        width={50}
                        className="mr-2 md:mr-4 w-8 sm:w-12"
                        placeholder="blurred"
                      />
                      <span className="leading-8 text-sm md:text-xl font-bold tracking-tight text-center hidden sm:block">
                        <FormattedMessage id="title" />
                      </span>
                      <span className="leading-8 text-xs md:text-xl font-bold tracking-tight text-center block sm:hidden">
                        <FormattedMessage id="short_title" />
                      </span>
                    </h1>
                  </Link>
                );
              } else {
                return (
                  <Link to="/">
                    <h1 className="flex items-center text-white no-underline">
                      <StaticImage
                        src="../images/emblem.png"
                        alt="National Emblem of Myanmar"
                        width={50}
                        className="mr-2 md:mr-4 w-8 sm:w-12"
                        placeholder="blurred"
                      />
                      <span className="leading-8 text-sm md:text-xl font-bold tracking-tight text-center hidden sm:block">
                        <FormattedMessage id="title" />
                      </span>
                      <span className="leading-8 text-xs md:text-xl font-bold tracking-tight text-center block sm:hidden">
                        <FormattedMessage id="short_title" />
                      </span>
                    </h1>
                  </Link>
                );
              }
            }}
          </Location>
          {showLangSwitch ? (
            <div className="flex flex-row md:flex-wrap justify-end w-auto mt-0 ml-auto text-white text-xs md:text-sm">
              <Link to={routeUrl("my", location.pathname)}>မြန်မာ</Link>
              &nbsp;|&nbsp;
              <Link to={routeUrl("en", location.pathname)}>English</Link>
            </div>
          ) : (
            <span></span>
          )}
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  showLangSwitch: PropTypes.bool.isRequired,
  location: PropTypes.any.isRequired,
};

export default Header;
