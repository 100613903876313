import PropTypes from "prop-types";
import React from "react";
import Header from "./header";
import Footer from "./footer";
// import Message from "./message";
import { getCurrentLangKey } from "ptz-i18n";
import { StaticQuery, graphql } from "gatsby";
import { IntlProvider } from "react-intl";
import "intl";

function Layout({ children, showLangSwitch, location, i18nMessages }) {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }
            }
          }
        }
      `}
      render={(data) => {
        const url = location.pathname;
        const { langs, defaultLangKey } = data.site.siteMetadata.languages;
        const langKey = getCurrentLangKey(langs, defaultLangKey, url);
        return (
          <IntlProvider locale={langKey} messages={i18nMessages}>
            <div className="flex flex-col min-h-screen font-sans text-gray-900 h-screen">
              <Header showLangSwitch={showLangSwitch ?? true} location={location} />
              {/* <Message location={location} /> */}
              <div className="flex-1 w-full overflow-y-auto">
                <main>{children}</main>
                <Footer />
              </div>
            </div>
          </IntlProvider>
        );
      }}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showLangSwitch: PropTypes.bool,
  location: PropTypes.any,
  i18nMessages: PropTypes.any,
};

export default Layout;
